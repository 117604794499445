import { ErrorsConfig } from 'ngx-customapp-errors'
import { ErrorsReporterService } from '../services/errors-reporter.service'
import { defaultLocale } from './locale'
import { ruRuCommonErrors } from 'ngx-customapp-errors'
import { ErrorResponse } from 'common/models/error-response'
import { HttpErrorResponse } from '@angular/common/http'

export const createSdkErrorsConfig = (production: boolean): ErrorsConfig => ({
  isErrorResponse: (response: HttpErrorResponse) => response?.error?.message && response?.error?.code !== undefined,
  errorResponseToNormalizedError: (response: HttpErrorResponse) =>
    response?.error?.message_loc ?? response?.error?.message ?? '',
  errorResponseToAppendix: (errorResponse: ErrorResponse) => errorResponse?.detail,
  reporter: ErrorsReporterService,
  production: production,
  errorsText: {
    [defaultLocale]: {
      ...ruRuCommonErrors,
      'failed auth': 'Неправильный логин или пароль'
    }
  },
  doNotSend: ['no connection', 'failed auth', 'invalid version']
})
