import { Injectable } from '@angular/core'
import { AppState } from '../state'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { SuccessResponse } from '../../../../common/models/success-response'
import { AllowWitdrawalRequest } from '../../../../common/models/allow-witdrawal-request'
import { AllowWitdrawalThisMonthRequest } from '../../../../common/models/allow-witdrawal-this-month-request'
import { MinionService } from './minion.service'
import { tapDispatch } from '../../../../common/utils/store/actions'
import {
  minionGodMode,
  minionPasswordRecovery,
  minionShowInGradesRatingUpdated,
  minionShowReceivedCarInGradesRatingUpdated,
  minionUpdated,
  minionUpdateError,
  minionWithdrawalsUpdated,
  minionWithdrawalsThisMonthUpdated,
  minionWithdrawalLimitUpdated
} from './minion.actions'
import { AllUserDataResp } from '../../../../common/models/all-user-data-resp'
import { UpdateGroupOrderAccessRequest } from '../../../../common/models/update-group-order-access-request'
import { IncreaseBalanceRequest } from '../../../../common/models/increase-balance-request'
import { UpdateUserAccessRequest } from '../../../../common/models/update-user-access-request'
import { BlockUserRequest } from 'common/models/block-user-request'
import { UpdateUserInfoRequest } from '../../../../common/models/update-user-info-request'
import { AccruePresidentialPoolRequest } from '../../../../common/models/accrue-presidential-pool-request'
import { AccrueGoldProGiftRequest } from '../../../../common/models/accrue-gold-pro-gift-request'
import { ShowInGradesRatingRequest } from '../../../../common/models/show-in-grades-rating-request'
import { ShowReceivedCarInGradesRatingRequest } from 'common/models/show-received-car-in-grades-rating-request'
import { AdminPassRecoveryRequest } from 'common/models/admin-pass-recovery-request'
import { EnterGodMode } from '../../../../common/models/enter-god-mode'
import { AuthResponse } from '../../../../common/models/auth-response'
import { SwapMinionsRequest } from '../../../../common/models/swap-minions-request'
import { UserIdRequest } from '../../../../common/models/user-id-request'

@Injectable()
export class MinionEffects {
  constructor(private _store: Store<AppState>, private _minionService: MinionService) {}

  allowWithdrawals(req: AllowWitdrawalRequest): Observable<SuccessResponse> {
    return this._minionService.allowWithdrawals(req).pipe(
      tapDispatch(
        this._store,
        () => minionWithdrawalsUpdated({ data: req }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  updateWithdrawalLimit(req: AllowWitdrawalRequest): Observable<SuccessResponse> {
    return this._minionService.updateWithdrawalLimit(req).pipe(
      tapDispatch(
        this._store,
        () => minionWithdrawalLimitUpdated({ data: req }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  allowWithdrawalsThisMonth(req: AllowWitdrawalThisMonthRequest): Observable<SuccessResponse> {
    return this._minionService.allowWithdrawalsThisMonth(req).pipe(
      tapDispatch(
        this._store,
        () => minionWithdrawalsThisMonthUpdated({ data: req }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  updateGroupOrder(req: UpdateGroupOrderAccessRequest): Observable<AllUserDataResp> {
    return this._minionService.updateGroupOrder(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  updateFakeDelivery(req: UpdateUserAccessRequest): Observable<AllUserDataResp> {
    return this._minionService.updateFakeDelivery(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  updateShowReceivedCarInGradesRating(req: ShowReceivedCarInGradesRatingRequest): Observable<SuccessResponse> {
    return this._minionService.updateShowReceivedCarInGradesRating(req).pipe(
      tapDispatch(
        this._store,
        () => minionShowReceivedCarInGradesRatingUpdated({ data: req }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  updateShowInGradesRating(req: ShowInGradesRatingRequest): Observable<SuccessResponse> {
    return this._minionService.updateShowInGradesRating(req).pipe(
      tapDispatch(
        this._store,
        () => minionShowInGradesRatingUpdated({ data: req }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  increaseBalance(req: IncreaseBalanceRequest): Observable<AllUserDataResp> {
    return this._minionService.increaseBalance(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  deleteUser(req: UserIdRequest): Observable<AllUserDataResp> {
    return this._minionService.deleteUser(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  gradeToPartner(req: UserIdRequest): Observable<AllUserDataResp> {
    return this._minionService.gradeToPartner(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  downgradeUser(req: UserIdRequest): Observable<AllUserDataResp> {
    return this._minionService.downgradeUser(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  moveToCic(req: UserIdRequest): Observable<AllUserDataResp> {
    return this._minionService.moveToCic(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  blockUser(req: BlockUserRequest): Observable<AllUserDataResp> {
    return this._minionService.blockUser(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  updateUserInfo(req: UpdateUserInfoRequest): Observable<AllUserDataResp> {
    return this._minionService.updateUserInfo(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  accruePresidentialPool(req: AccruePresidentialPoolRequest): Observable<AllUserDataResp> {
    return this._minionService.accruePresidentialPool(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  accrueGoldProGift(req: AccrueGoldProGiftRequest): Observable<AllUserDataResp> {
    return this._minionService.accrueGoldProGift(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  passwordRecovery(req: AdminPassRecoveryRequest): Observable<SuccessResponse> {
    return this._minionService.passwordRecovery(req).pipe(
      tapDispatch(
        this._store,
        () => minionPasswordRecovery(),
        (error: string) => minionUpdateError({ error })
      )
    )
  }

  godMode(req: EnterGodMode): Observable<AuthResponse> {
    return this._minionService.godMode(req).pipe(
      tapDispatch(
        this._store,
        data => minionGodMode.success({ data }),
        (error: string) => minionGodMode.error({ error })
      )
    )
  }

  swap(req: SwapMinionsRequest): Observable<AllUserDataResp> {
    return this._minionService.swap(req).pipe(
      tapDispatch(
        this._store,
        data => minionUpdated({ data }),
        (error: string) => minionUpdateError({ error })
      )
    )
  }
}
