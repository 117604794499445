import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { RequestService } from '../../../../../common/services/request.service'
import { Order } from '../../../../../common/models/order'
import { OrderIdRequest } from '../../../../../common/models/order-id-request'
import { OrderIntIdRequest } from '../../../../../common/models/order-int-id-request'
import { OrderChangeClientRequest } from '../../../../../common/models/order-change-client-request'
import { SuccessResponse } from 'common/models/success-response'
import { OrderVisibilityReq } from 'common/models/order-visibility-req'
import { OrderIntOrArrayIdRequest } from 'common/models/order-int-or-array-id-request'

@Injectable({
  providedIn: 'root'
})
export class OrderSelectedService {
  constructor(private _request: RequestService) {}

  load(req: OrderIdRequest): Observable<Order> {
    return this._request.post('/api/orders/get/one', req)
  }

  refund(req: OrderIntIdRequest): Observable<Order> {
    return this._request.post('/api/orders/refund', req)
  }

  markRefund(req: OrderIntIdRequest): Observable<Order> {
    return this._request.post('/api/orders/mark_refund', req)
  }

  delete(req: OrderVisibilityReq): Observable<SuccessResponse> {
    return this._request.delete('/api/orders/change_order_visibility', req)
  }

  changeClient(req: OrderChangeClientRequest): Observable<Order> {
    return this._request.post('/api/orders/change_order_client', req)
  }

  cancelOrder(order_id: string): Observable<Order> {
    return this._request.post('/api/orders/cancel', { order_id })
  }

  resendTo1C(req: OrderIntOrArrayIdRequest): Observable<SuccessResponse> {
    return this._request.post('/api/admin/resend_1c_order', req)
  }

  repeatOrder(order_id: string) {
    return this._request.post('/api/orders/v2/repeat', { order_id, to_clear: false })
  }
}
