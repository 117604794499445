import { createReducer, on } from '@ngrx/store'
import {
  minionStocksVisibilityClear,
  minionStocksVisibilityLoad,
  minionStocksVisibilitySwitcher
} from './minion-stocks-visibility.actions'
import { Stock } from 'common/models/stock'

export const minionStocksVisibilityFeatureKey = 'minionStocksVisibility'

export interface State {
  countVisibility: number
  stocksVisibility: Stock[]
  isLoading: boolean
  isLoaded: boolean
}

export const initialState: State = {
  countVisibility: 0,
  stocksVisibility: [],
  isLoading: false,
  isLoaded: false
}

export const reducer = createReducer(
  initialState,
  on(minionStocksVisibilityLoad.start, state => ({ ...state, isLoading: true, isLoaded: false })),
  on(minionStocksVisibilityLoad.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(minionStocksVisibilityLoad.success, (state, { data }) => ({
    ...state,
    countVisibility: data.stocks?.length ?? 0,
    stocksVisibility: data.stocks ?? [],
    isLoading: false,
    isLoaded: true
  })),
  on(minionStocksVisibilitySwitcher.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(minionStocksVisibilitySwitcher.success, state => ({ ...state, isLoading: false, isLoaded: true })),
  on(minionStocksVisibilitySwitcher.start, (state, { req }) => ({
    ...state,
    isLoaded: false,
    isLoading: true,
    stocksVisibility: state.stocksVisibility.map(stock =>
      stock.id === req.stock_id ? { ...stock, user_has_access: req.permission } : stock
    )
  })),
  on(minionStocksVisibilityClear, () => initialState)
)
