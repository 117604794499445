import { JwtConfig, NoFreshJwtListenerConstructor } from 'ngx-customapp-jwt'
import { authHeader, authResponseToJwt, Credentials, jwtToCredentials } from '../utils/user/auth'
import { authResponseToUserInfo, UserInfo } from '../utils/user/user'
import { JwtApiService } from '../services/jwt-api.service'
import { AuthResponse } from 'common/models/auth-response'
import { cdnUrlPattern } from './ngx-optimized-image'

export const createSdkJwtConfig = (
  jwtStorageKey: string,
  noFreshJwt: NoFreshJwtListenerConstructor
): JwtConfig<Credentials, AuthResponse, UserInfo> => ({
  authHeader,
  excludeUrls: [cdnUrlPattern],
  jwtGuard: {
    jwtToCredentials
  },
  jwtStorageKey,
  noFreshJwt: {
    service: noFreshJwt,
    callWhen: {
      interceptorNotFoundJwt: false,
      guardNotFoundJwt: true,
      loginAsJwtNotFound: true
    }
  },
  authResponseToUserInfo,
  authResponseToJwt,
  jwtApi: JwtApiService
})
