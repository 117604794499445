import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { City } from 'common/models/city'
import { Country } from 'common/models/country'
import { Region } from 'common/models/region'

export const loadCountries = createActionGroup({
  source: 'Load countries',
  events: {
    start: emptyProps(),
    success: props<{ countries: Country[] }>(),
    error: props<{ error: string }>()
  }
})

export const loadRegions = createActionGroup({
  source: 'Load regions',
  events: {
    start: props<{ name: string; countryId: number }>(),
    success: props<{ regions: Region[] }>(),
    error: props<{ error: string }>()
  }
})

export const loadCities = createActionGroup({
  source: 'Load cities',
  events: {
    start: props<{ name: string; countryId: number; regionId?: number }>(),
    success: props<{ cities: City[] }>(),
    error: props<{ error: string }>()
  }
})

export const removeCountries = createAction('[Countries] remove')
export const removeRegions = createAction('[Regions] remove')
export const removeCities = createAction('[Cities] remove')

export const allErrors = [loadCountries.error, loadRegions.error, loadCities.error]
