import { Actions, ofType } from '@ngrx/effects'
import { Action, ActionCreator, Store } from '@ngrx/store'
import { NotAllowedCheck, TypedAction } from '@ngrx/store/src/models'
import { EMPTY, filter, Observable, take, takeUntil, tap } from 'rxjs'

export function onActions<T extends string, E extends string, P extends object, S extends object>(
  actions$: Actions,
  actionOkType: ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & TypedAction<T>>,
  actionErrorType: ActionCreator<E, (props: S & NotAllowedCheck<S>) => S & TypedAction<E>>,
  okCallback: (a: P & TypedAction<T>) => void, // using callbacks, cos promises lose typing in reject()
  errCallback: (a: S & TypedAction<E>) => void,
  until$?: Observable<unknown>
): void {
  actions$.pipe(ofType(actionOkType, actionErrorType), take(1), takeUntil(until$ || EMPTY)).subscribe(action => {
    if (action.type === actionOkType.type) {
      okCallback(action as P & TypedAction<T>)
    } else if (action.type === actionErrorType.type) {
      errCallback(action as S & TypedAction<E>)
    } else {
      throw new Error('unexpected action type ' + action.type)
    }
  })
}

export function tapDispatch<T, E>(
  store: Store<unknown>,
  nextFn: (next: T) => Action,
  errorFn: (error: E) => Action
): (source: Observable<T>) => Observable<T> {
  return source =>
    source.pipe(
      tap({
        next: next => store.dispatch(nextFn(next)),
        error: error => store.dispatch(errorFn(error))
      })
    )
}

// Осторожно с работой с boolean, так как по умолчанию false будет отфильтровываться.
// Так же осторожно, если null, undefined или 0 являются валидными значениями и важны. Они тоже будут отфильтрованы.
export function withLatestItem<ValueType>(
  observable: Observable<ValueType>,
  callback?: (value: ValueType) => void,
  filtrate = true
): void {
  observable
    .pipe(
      filter(value => !filtrate || !!value),
      take(1)
    )
    .subscribe(value => (callback ? callback(value) : null))
}
