import { MinionService } from '../minion.service'
import { catchError, map, mergeMap, of } from 'rxjs'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { minionStocksVisibilityLoad, minionStocksVisibilitySwitcher } from './minion-stocks-visibility.actions'

@Injectable()
export class MinionStocksVisibilityEffects {
  loadStocksVisibility$ = createEffect(() =>
    this._actions$.pipe(
      ofType(minionStocksVisibilityLoad.start),
      mergeMap(action =>
        this._minionService.loadStocksVisibility(action.userId).pipe(
          map(data => minionStocksVisibilityLoad.success({ data })),
          catchError(error => of(minionStocksVisibilityLoad.error({ error })))
        )
      )
    )
  )

  switchStockVisibility$ = createEffect(() =>
    this._actions$.pipe(
      ofType(minionStocksVisibilitySwitcher.start),
      mergeMap(action =>
        this._minionService.toggleVisibilityStock(action.req).pipe(
          map(res => minionStocksVisibilitySwitcher.success({ res })),
          catchError(error => of(minionStocksVisibilitySwitcher.error({ error })))
        )
      )
    )
  )

  constructor(private _actions$: Actions, private _minionService: MinionService) {}
}
