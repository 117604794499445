import { from, map, Observable, of, zip } from 'rxjs'
import { Buffer } from 'buffer'

export interface FileB64 {
  name: string
  type: string
  size: number
  b64: string
}

export const fileToB64 = (file: File): Observable<FileB64> => {
  return from(file.arrayBuffer()).pipe(
    map(buffer => ({
      name: file.name,
      type: file.type,
      size: file.size,
      b64: Buffer.from(buffer).toString('base64')
    }))
  )
}

export const fileListToB64 = (fileList: File[]): Observable<FileB64[]> => {
  if (!fileList?.length) {
    return of([])
  }
  return zip(fileList.map(file => fileToB64(file)))
}
